.delegator-actions {
  li {
    list-style: none;
    cursor: pointer;
    padding: 6px;
    &:hover {
      scale: 1.1;
    }
    span {
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
  }
  .disabled-action {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    &:hover {
      scale: 1 !important;
    }
  }
}

.custom-search-dropdown {
  margin-right: 20px;
  .react-autosuggest__input {
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .react-autosuggest__container {
    flex: 1 1 auto;
    // width: 1%;
    min-width: 0;
    margin-bottom: 0;
    min-width: 300px;
    input {
      color: white;
      width: 100%;
      border-radius: inherit;
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
      border-radius: 16px;
      &:focus-visible {
        border: 2px solid #fff !important;
        outline: none;
      }
    }
  }
  .react-autosuggest__container--open {
    // input {
    //   border-bottom-left-radius: 0rem;
    //   border-bottom-right-radius: 0rem;
    // }
  }
  .react-autosuggest__suggestions-list {
    list-style: none;
    border: none;
    padding-inline-start: 0px;
    position: absolute;
    z-index: 100;
    max-height: 200px;
    margin-top: 0.1rem;
    width: calc(100%);
    overflow: scroll;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    cursor: pointer;
    backdrop-filter: blur(50px);
    box-shadow: 0px 24px 38px 3px rgb(0 0 0 / 14%),
      0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 11px 15px -7px rgb(0 0 0 / 20%);
  }
  .suggestion-content {
    margin: 8px;
    padding: 12px;
    border-bottom: 12px;
  }
  .react-autosuggest__suggestion {
    .suggestion-content {
      border: 1px solid transparent;
      border-radius: 4px;
    }
  }
  .react-autosuggest__suggestion--highlighted {
    .suggestion-content {
      border: 1px solid #fff;
      background: transparent;
      border-radius: 4px;
    }
  }
  .autosuggest-blockies {
    display: flex;
    align-items: center;
    .identicon {
      border-radius: 50%;
      margin-right: 6px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.h-100 {
  height: 100%;
  padding: 4px;
}

.disabled-action {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  &:hover {
    scale: 1 !important;
  }
}

.custom-multi-select {
  max-width: 300px;
  .chakra-input {
    display: flex;
    // max-height: 40px;
    max-width: 20rem;
    div {
      // max-height: 40px;
      display: flex;
    }
  }
}
