.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 6px 3px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-6px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

a {
  color: #63b3ed !important;
}

.indenticon {
    border-radius: 50%;
    margin-right: 0.625rem;
}

.custom-ellipsis {
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px 2px;
}

.code-ellipsis{
    white-space: nowrap;
    overflow: scroll;
    max-width: 100px;
  }

  .code-ellipsis-header{
    white-space: nowrap;
    overflow: scroll;
    max-width: 400px;
  }


  .scroll-content{
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto !important;
    overflow-y: hidden;
    max-width: 100%;
  }

  .overflow-x-hidden{
    overflow-x: hidden !important;
  }